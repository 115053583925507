import React, { Component } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Company from "../components/danismanlik-company"
import rightArrow from "../assets/img/right-arrow.png";

import marka from "../assets/img/yenigifler.gif"
import surec1 from "../assets/img/button1.png"
import surec2 from "../assets/img/button2.png"
import smgif from "../assets/img/smgif.gif"
import filtregif from "../assets/img/filtregif.gif"
import nkl from "../assets/img/nklnew.png"
import arfiltre from "../assets/img/ar-filtre-head.png"
import work from "../assets/img/work-preformance.png"
import Checkup from "../components/checkup"
import checkModal from "../assets/img/check-modal.png";
import Companys from "../components/web-company"
import stm from "../assets/img/companys/22.png"
import logo from "../assets/img/logo.png"


class SocialMedia extends Component {
    constructor(props) {
        super(props);

        this.state = {
            display: false,
            value: ''
        };
        this.handleChange = this.handleChange.bind(this);
    }
    handleChange(e) {
        e.preventDefault();

        this.setState({ value: e.target.value });
    }
    showModal() {
        this.setState({ display: !this.state.display });
        document.querySelector('.check-modal').classList.add('active-modal');
    }
    closeModal() {
        this.setState({ display: !this.state.display })
        document.querySelector('.check-modal').classList.remove('active-modal');
    }
    handleSubmit(e) {
        e.preventDefault();
        const title = document.title.split('|', 1).toString();
        const response = fetch('https://forms.flatart.com.tr/index.php?r=app%2Ff&id=7', {
            method: 'POST',
            body: JSON.stringify({
                "text_327649": title,
                "text_204202": document.querySelector('#site').value,
                "text_775908": document.querySelector('#name').value,
                "text_532192": document.querySelector('#email').value,
            }),
            headers: {
                "Content-type": "application/json; charset=UTF-8"
            }
        });
        response.then(res => {
            if (res.status === 200) {
                document.querySelector('.status').innerHTML = "Mesajınız başarıyla gönderilmiştir.";
            }
            else {
                document.querySelector('.status').innerHTML = "Bir hata oluştu.";
            }
        });
    }
    render() {
        return (
            <Layout>
                <SEO title="AR Filtre" description="Her gun değişen sosyal medya trendlerini izliyor, kitleleri etkileyecek fark yaratan, alışılmışın dışında içerikler tasarlıyoruz." />
                <div className="seo-service container">
                    <div className="seo-service__head">
                        <div className="col-md-6 noPadding">
                            <h1>AR Filtre</h1>
                            <span className="red-head">HİZMETLER</span>
                            <p style={{ maxWidth: 450 }} className="arfiltre" > Çevrimiçi satışlarınızı, marka bilinirliğinizi üst düzeye çıkarmak ve sosyal medya kullanıcıları ile eğlenceli şekilde temas kurmanız için AR filtreler tasarlıyoruz. </p>
                            <input type="text" className="pink-input" placeholder="E-posta adresinizi girin..." value={this.state.value} onChange={this.handleChange} />
                            <button className="red-button" id="social-button" onClick={(e) => this.showModal()}>Size özel teklif alın <img alt="alt text" src={rightArrow}></img></button>
                        </div>
                        <div className="col-md-6" style={{ marginTop: 33.5, paddingLeft: 50 }}><img alt="alt text" src={arfiltre} /> </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form onSubmit={this.handleSubmit} >
                                    <input type="text" name="text_204202" id="site" required placeholder="Websitenizi girin." />
                                    <input type="text" id="name" required name="text_775908" placeholder="Adınızı girin." />
                                    <input type="email" id="email" required name="text_532192" value={this.state.value} onChange={this.handleChange} autoFocus={true} placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                    <div className="status"></div>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                        <div className="check-modal">
                            <span onClick={(e) => this.closeModal()}>X</span>
                            <h2 className="form-head">Formu doldurun.</h2>                               <p className="form-desc">Bilgi almak için bilgilerinizi formdaki alanlara girin, en kısa sürede size dönüş yapalım.</p>
                            <div className="check-modal_container">
                                <form>
                                    <input type="text" value={this.state.value} onChange={this.handleChange} autoFocus id="site" required />
                                    <input type="text" placeholder="Adınızı girin." />
                                    <input type="email" placeholder="E-posta adresinizi girin." />
                                    <button  >GÖNDER</button>
                                </form>
                                <img alt="alt text" src={checkModal} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container brands_content">
                    <h2 style={{ color: '#ea4f6a' }}>
                        AR Filtre Nedir?
                    </h2>
                    <p className="arfiltre">AR yani Augmented Reality, "Artırılmış Gerçeklik” anlamına gelen bir sosyal medya kavramıdır. AR filtre, cihazların cisim tanıma fonksiyonunun devreye girmesi sayesinde sanal nesnelerin gerçek obje görüntülerinin üzerine eklenmesi olarak tanımlanır.
</p>

                </div>

                <div className="brands_content">
                    <h2 style={{ fontWeight: 700 }}>
                        Öne çıkan işlerimizden bazıları.
                        <svg className="comment-icon" id="Group_817" data-name="Group 817" xmlns="http://www.w3.org/2000/svg" width="50.007" height="55.09" viewBox="0 0 87.007 85.09">
                            <g id="Group_813" data-name="Group 813" transform="translate(0 0)">
                                <path id="Path_23741" data-name="Path 23741" d="M70.6,0H16.409A16.409,16.409,0,0,0,0,16.409V46.938A16.409,16.409,0,0,0,16.409,63.347h5.263a4.458,4.458,0,0,1,3.316,1.478l17.223,19.16a3.336,3.336,0,0,0,5.034-.083L63.2,64.935a4.459,4.459,0,0,1,3.412-1.588H70.6A16.409,16.409,0,0,0,87.007,46.938V16.409A16.41,16.41,0,0,0,70.6,0Z" transform="translate(0 0)" fill="#ed4a57" />
                            </g>
                            <g id="Group_816" data-name="Group 816" transform="translate(17.681 19.866)">
                                <g id="Group_814" data-name="Group 814">
                                    <path id="Path_23742" data-name="Path 23742" d="M311.748,326.479a7.388,7.388,0,0,0-13.507-3.646.183.183,0,0,1-.306,0A7.387,7.387,0,1,0,287.4,332.884l9.87,9.095a.991.991,0,0,0,1.333.009l10-8.984A7.379,7.379,0,0,0,311.748,326.479Z" transform="translate(-284.414 -319.562)" fill="#fff" />
                                </g>
                                <g id="Group_815" data-name="Group 815" transform="translate(39.121 1.067)">
                                    <path id="Path_23743" data-name="Path 23743" d="M918.009,339.391h-.065l-3.68,1.987-.553-2.182,4.625-2.475h2.443v21.17h-2.768Z" transform="translate(-913.71 -336.72)" fill="#fff" />
                                </g>
                            </g>
                        </svg>
                    </h2>
                    <img className="gifImage" style={{ marginTop: 50 }} alt="alt text" src={marka}></img>

                </div>
                <div className="brands_content">
                    <img className="gifImageNew" style={{ marginTop:-60 }} alt="alt text" src={nkl}></img>
                </div>
                
               <div className="container brands_content">
                    <h2 style={{ color: '#ea4f6a' }}>
                        AR Filtre Üretim Süreci
                    </h2>
                </div>
                <div className="onboarding container py60">

                    <div className="col-md-6">
                        <p className="arfiltre">Özel günler, kampanya dönemleri ve etkinliklerde online satışlarınızı, marka bilinirliğinizi en üst düzeye çıkarmanın ve kullanıcılarla iletişim kurmanın eğlenceli ve ekonomik yollarından biri de AR filtreleri kullanmaktır. Sosyal medya kullanıcıları tarafından sıklıkla kullanılan filtreler aracılığıyla marka bilinirliğinizi organik olarak besleyebilirsiniz. İstediğiniz şekilde tasarlanan ve kullanıcı etkileşimine açık olan AR filtre hizmetimiz sayesinde markanızın kimliğine uygun kurgu ve senaryo çalışması yapılır.</p>
                    </div>
                    <div className="col-md-6 filtreImage">
                        <img style={{ minWidth: 500 }} alt="alt text" src={surec1}></img>
                    </div>

                </div>
                <div className="onboarding container">
                    <div className="col-md-6 filtreImage">
                        <img style={{ minWidth: 500 }} alt="alt text" src={surec2}></img>
                    </div>
                    <div className="col-md-6">
                        <p className="arfiltre">Oluşturulan AR filtre kurgusu doğrultusunda, akılda kalıcı ve dikkat çekici tasarımı hazırlayarak kullanılacağı platformda yayına hazır hale getiriyoruz. Tasarlamış olduğumuz tüm AR filtreler son derece özgün ve çarpıcı detaylar içerir. Bu yüzden her biri de tamamen akılda kalıcı ve ilgi çekicidir.
                        </p>
                    </div>

                </div>
             
                <div className="container py60">
                    <div className="col-md-2">

                    </div>
                    <div className="col-md-4" >
                        <img alt="alt text" style={{ marginLeft:-30}}  src={filtregif}></img>
                    </div>
                    <div className="col-md-4">
                        <img alt="alt text" src={smgif}></img>
                    </div>
                    <div className="col-md-2">

                    </div>

                </div>
               
                <div className="container brands_content">
                <h2 style={{ color: '#ea4f6a' }}>
                    AR Filtre Kullanmanın Avantajları
                    </h2>
                    <div className="list-container">
                      <p className="list-item">• Etkileşim: AR filtreleri, tüketicilerin bir markayla eğlenceli ve ilgi çekici bir şekilde etkileşime girmesine olanak tanıyarak marka bilinirliğini artırır.</p>
                      <p className="list-item">• Uygun Maliyet: AR filtreler, nispeten düşük bir maliyetle oluşturulabilir ve sosyal medya platformlarında kullanılabilir. Böylelikle uygun maliyetli bir pazarlama aracı haline gelir.</p>
                      <p className="list-item">• Ölçülebilirlik: AR filtrelerin ölçülebilir olması, hazırlanan kampanyaların etki oranının kolaylıkla değerlendirilebilmesini sağlar.</p>
                      <p className="list-item">• Mobil Uyum: Mobil cihazlarda kullanılabilir olması AR filtreleri, geniş bir kitleye ulaşmak için uygun bir pazarlama aracı haline getirir.</p>
                    </div>
                </div>

                <Checkup />





            </Layout>
        )
    }
}
export default SocialMedia
